<template>
	<div class="market-banner-switch">
    <div class="market-banner-switch-inner">
        <div 
          :class="['market-banner-switch-group', {'actived':slideCurrentIdx == index }]" 
          v-for="(banner, index) in bannerList" 
          :key="index" 
          :style="banner.image"
          @touchstart.prevent="(e) => setTouchPoint(e)"
          @touchend.prevent="(e) => judgeGoNext(e, banner.link)"
          v-if="index < bannerLength"
        >
            <div class="market-banner-switch-group-title">
                {{slideTextList[index].title}}
            </div>
            <div class="market-banner-switch-group-desc" v-for="text in slideTextList[index].text">
                {{text}}
            </div>
        </div>

        <div class="market-banner-switch-dot">
            <span v-for="(banner, index) in bannerList" 
            :key="index"
            v-if="index < bannerLength"
            @touchend.prevent="slideClickTrigger(index)"
            :class="['market-banner-switch-bullet', {'actived':slideCurrentIdx == index }]"></span>
        </div>
    </div>
  </div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  name:'slideBoxMobile',
  data(){
    return {
      isEnter: false,
      slideIntevId: 0,
      slideCurrentIdx: 0,
      bannerList: [],
      startPoint: {x: 0, y: 0}
    }
  },
  computed: {
    ...mapState('commonData', {
      slideTextList: s => s.slideTextList
    }),
    bannerLength() {
      return this.bannerList.length > this.slideTextList.length ? this.slideTextList.length : this.bannerList.length
    }
  },
  methods: {

    setTouchPoint(e) {
      this.startPoint = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY
      }
    },
    judgeGoNext(e, url) {
      const now = {
        x: e.changedTouches[0].pageX,
        y: e.changedTouches[0].pageY
      }
      // 滑动翻页
      if (Math.abs(now.x - this.startPoint.x) > 20) {
        if (now.x > this.startPoint.x) {
          this.slideCurrentIdx === 0 ? this.changeCrrentSlide(this.bannerLength - 1) : this.changeCrrentSlide(this.slideCurrentIdx - 1)
        } else {
          this.slideCurrentIdx === this.bannerLength - 1 ? this.changeCrrentSlide(0) : this.changeCrrentSlide(this.slideCurrentIdx + 1)
        }
      } else {
        window.open(url)
      }
    },
    //slide 到时可抽成组件
    setImgStyle () {
      const DISPATCH = this.$store.dispatch
      let idx = this.slideCurrentIdx
      DISPATCH('componentSlide/changeImage', idx)
    },
    changeCrrentSlide (index) {
      const DISPATCH = this.$store.dispatch
      this.clearSlideIntervial()
      this.slideCurrentIdx = index
      DISPATCH('componentSlide/changeImage', index)
      this.setSlideIntervial()
    },
    setSlideIntervial () {
      let self = this
      self.bannerList.length && (self.slideIntevId = setInterval(() => {
        self.slideNextTrigger()
      }, 5000))
    },
    clearSlideIntervial () {
      clearInterval(this.slideIntevId)
    },
    slideClickTrigger (index) {
      this.changeCrrentSlide(index)
    },
    slidePreTrigger () {
      if (this.slideCurrentIdx == 0) {
        this.changeCrrentSlide(this.bannerLength - 1)
      } else {
        this.changeCrrentSlide(this.slideCurrentIdx - 1)
      }
    },
    slideNextTrigger () {
      if (this.slideCurrentIdx >= this.bannerLength - 1) {
        this.changeCrrentSlide(0)
      } else {
        this.changeCrrentSlide(this.slideCurrentIdx + 1)
      }
    }
  },
  mounted(){
    const SELF = this
    const DISPATCH = this.$store.dispatch

    DISPATCH('componentSlide/getSlideList').then((re)=>{
      let listData;
      if(re && re.listData && re.listData.length){
        SELF.bannerList = re.listData
        SELF.setImgStyle()
        SELF.setSlideIntervial()
      }
      console.log(re.listData)
    })
  }
}
</script>
