<template>
	<div class="market-banner-section-main">
    <div class="market-banner-carousel">
        <div class="market-banner-carousel-container">
            <div :class="['market-banner-carousel-item', {'actived':slideCurrentIdx == index }]"  v-for="(banner, index) in bannerList" :key="index" v-if="index < bannerLength">
                <a href="" class="market-banner-carousel-item-detail" target="_blank"
                  :href="banner.link || 'javascript:void(0)'"
                  :style="banner.image"
                  :key="index">
                    <div class="market-banner-carousel-inner">
                        <p class="market-banner-carousel-inner-title">{{slideTextList[index].title}}</p>
                        <p class="market-banner-carousel-inner-text" v-for="text in slideTextList[index].text">{{text}}</p>
                        <button class="rno-btn rno-btn-white-hole" v-if="slideTextList[index].needSeeInfo"><span class="rno-btn-text">{{slideTextList[index].needSeeInfo}}</span></button>
                    </div>
                </a>
            </div>
        </div>
        <div class="market-banner-carousel-tabs">
            <a href="" v-for="(slideImg, index) in bannerList"
              :class="['market-banner-carousel-tab-item',{'actived':index == slideCurrentIdx}]"
              @click.prevent="slideClickTrigger(index)"
              v-if="index < bannerLength"
              :key="index">
            </a>
        </div>
    </div>
</div>
</template>

<script>
import {
  mapState,
  mapActions
} from 'vuex'

export default {
  name:'slideBox',
  data(){
    return {
      isEnter: false,
      slideIntevId: 0,
      slideCurrentIdx: 0,
      bannerList: []
    }
  },
  computed: {
    ...mapState('commonData', {
      slideTextList: s => s.slideTextList
    }),
    bannerLength() {
      return this.bannerList.length > this.slideTextList.length ? this.slideTextList.length : this.bannerList.length
    }
  },
  methods: {
    //slide 到时可抽成组件
    setImgStyle () {
      const DISPATCH = this.$store.dispatch
      let idx = this.slideCurrentIdx
      DISPATCH('componentSlide/changeImage', idx)
    },
    changeCrrentSlide (index) {
      const DISPATCH = this.$store.dispatch
      this.clearSlideIntervial()
      this.slideCurrentIdx = index
      DISPATCH('componentSlide/changeImage', index)
      this.setSlideIntervial()
    },
    setSlideIntervial () {
      let self = this
      self.bannerList.length && (self.slideIntevId = setInterval(() => {
        self.slideNextTrigger()
      }, 5000))
    },
    clearSlideIntervial () {
      clearInterval(this.slideIntevId)
    },
    slideClickTrigger (index) {
      this.changeCrrentSlide(index)
    },
    slidePreTrigger () {
      if (this.slideCurrentIdx == 0) {
        this.changeCrrentSlide(this.bannerLength - 1)
      } else {
        this.changeCrrentSlide(this.slideCurrentIdx - 1)
      }
    },
    slideNextTrigger () {
      if (this.slideCurrentIdx >= this.bannerLength - 1) {
        this.changeCrrentSlide(0)
      } else {
        this.changeCrrentSlide(this.slideCurrentIdx + 1)
      }
    }
  },
  mounted(){
    const SELF = this
    const DISPATCH = this.$store.dispatch

    DISPATCH('componentSlide/getSlideList').then((re)=>{
      let listData;
      if(re && re.listData && re.listData.length){
        SELF.bannerList = re.listData
        SELF.setImgStyle()
        SELF.setSlideIntervial()
      }
    })
  }
}
</script>
